import React from "react"
import PropTypes from "prop-types"
import { Container } from "react-bootstrap"

// Load scss styles
import "../../styles/bare.scss"

const Layout = ({ children }) => (
  <Container className="bare-container">
    <main>{children}</main>
  </Container>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
