import React from "react"
import { Download } from "@components"

import Bare from "@layout/bare"
import SEO from "@components/seo"
import AppIcon from "@static/svg/app-icon-150.svg"
import Star from "@static/svg/star.svg"

const DownloadPage = () => {
  return (
    <Bare>
      <SEO
        title="Download"
        description="Discover ATM.com, the micro-income app designed to help you earn more money. By making saving and investing a rewarding journey, we're enabling users to enhance their financial potential. Download our app today and turn your time into money!"
        image="/img/og/download-the-app.png"
      />
      <AppIcon width="80" height="80" className="app-icon" />
      <div className="ratings">
        <div className="stars">
          <Star width="10" height="10" />
          <Star width="10" height="10" />
          <Star width="10" height="10" />
          <Star width="10" height="10" />
          <Star width="10" height="10" />
        </div>
        <div className="rating">4.5 - 15K ratings</div>
      </div>
      <h1>ATM.com - Earn Money</h1>
      <p>
        ATM is a micro-income app that helps users earn more money to save and
        invest.
      </p>

      <Download
        id="download-page-buttons"
        showBothButtons
        style={{ textAlign: "center", width: "100%", maxWidth: "100%" }}
      />
    </Bare>
  )
}

export default DownloadPage
